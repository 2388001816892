import { useEffect, useState } from "react";
import styled from "styled-components";
import ListItems from "../../components/listItem";
import {
  Title,
  EmptyMessage,
  Input,
  InputLabel,
  Label,
  SaveButton,
  DeleteButton
} from "../../components/shared";
import firebase from "../../firebase";
import Modal from "../../components/modal";

export const InversorWrapper = styled.div``;

function Inversor() {
  const [inversors, setInversors] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newInversor, setNewInversor] = useState({
    label: "",
    value: "",
    potencia: "",
  });
  const [editingId, setEditingId] = useState("");
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    firebase
      .database()
      .ref("inversors")
      .on("value", function (snap) {
        setInversors(snap.val() || {});
      });
  }, []);

  function newRegister() {
    setShowModal(true);
    setEdit(false);
    setNewInversor({ label: "", value: "", potencia: "" });
  }

  function closeModal() {
    setShowModal(false);
  }

  function saveStruct() {
    if (!edit) {
      let path = firebase.database().ref("inversors");

      path.push(newInversor).then((res) => {
        setNewInversor({ label: "", value: "", potencia: "" });
        closeModal();
      });
    } else {
      let path = firebase.database().ref(`inversors/${editingId}`);
      path.set(newInversor).then(() => {
        setNewInversor({ label: "", value: "", potencia: "" });
        closeModal();
      });
    }
  }

  function handleChange(e) {
    setNewInversor({ ...newInversor, [e.target.name]: e.target.value });
  }

  function handleRemove(id) {
    const path = firebase.database().ref(`inversors/${id}`);
    
    const ok = window.confirm("Tem certeza que deseja excluir ?");
    
    if (ok) path.remove();
    setNewInversor({ label: "", value: "", potencia: "" });
    setEdit(false);
    closeModal();
  }

  function handleEdit(id) {
    firebase
      .database()
      .ref(`inversors/${id}`)
      .on("value", function (snap) {
        setNewInversor(snap.val());
        setEditingId(id);
        setEdit(true);
        setShowModal(true);
      });
  }

  return (
    <InversorWrapper>
      <Title>Inversores</Title>

      <ListItems
        potencia
        items={inversors}
        onEdit={handleEdit}
        onRemove={handleRemove}
      />

      <EmptyMessage onClick={newRegister}>
        Cadastrar Inversor
        <i className="fas fa-plus" />
      </EmptyMessage>

      <Modal
        close={closeModal}
        visible={showModal}
        title="inversor"
        edit={edit}
        clickBackdrop={() => setShowModal(false)}
      >
        <InputLabel>
          <Label>Nome do produto</Label>
          <Input
            value={newInversor.label}
            name="label"
            onChange={handleChange}
          />
        </InputLabel>

        <InputLabel>
          <Label>Potencia do inversor (KWp)</Label>
          <Input
            value={newInversor.potencia}
            name="potencia"
            onChange={handleChange}
          />
        </InputLabel>

        <InputLabel>
          <Label>Valor (R$)</Label>
          <Input
            value={newInversor.value}
            name="value"
            onChange={handleChange}
          />
        </InputLabel>

        <SaveButton onClick={saveStruct}>
          {edit ? "Editar" : "Salvar"}
        </SaveButton>

        {edit && (
          <DeleteButton onClick={() => handleRemove(editingId)}>
            Excluir
          </DeleteButton>
        )}
      </Modal>
    </InversorWrapper>
  );
}

export default Inversor;
