import { useEffect, useState } from "react";
import Logo from "../../assets/logo.jpeg";
import firebase from "../../firebase";
import { Error, Input, Image, LoginButton, LoginWrapper } from "./login.styles";

function Login() {
  const [user, setUser] = useState({ email: "", password: "" });
  const [error, setError] = useState({ message: "", code: "" });
  const [showPage, setShowPage] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((observable) => {
      if (observable) {
        return window.location.replace("/");
      }

      setShowPage(true);
    });
  }, []);

  if (!showPage) return null;

  function handleChange(e) {
    setError({ message: "", code: "" });
    setUser({ ...user, [e.target.name]: e.target.value });
  }

  function handleLogin() {
    let message = "";
    if (!user.email && !user.password) return;

    firebase
      .auth()
      .signInWithEmailAndPassword(user.email, user.password)
      .then((user) => {
        window.location.replace("/");
      })
      .catch((error) => {
        if (error.code === "auth/wrong-password") {
          message = "Email ou senha inválidos";
        }

        setError({ ...error, message });
      });
  }

  return (
    <LoginWrapper>
      <Image src={Logo} />
      <Input
        type="email"
        name="email"
        value={user.email}
        onChange={handleChange}
        placeholder="E-mail"
        error={!!error.message}
      />
      <Input
        name="password"
        value={user.password}
        onChange={handleChange}
        type="password"
        placeholder="Senha"
        error={!!error.message}
      />
      <Error>{error.message}</Error>
      <LoginButton type="submit" onClick={handleLogin}>
        Entrar
      </LoginButton>
    </LoginWrapper>
  );
}

export default Login;
