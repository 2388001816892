import { useEffect, useState } from "react";
import styled from "styled-components";
import ListItems from "../../components/listItem";
import {
  Title,
  EmptyMessage,
  Input,
  InputLabel,
  Label,
  SaveButton,
  DeleteButton,
} from "../../components/shared";
import firebase from "../../firebase";
import Modal from "../../components/modal";

export const InversorWrapper = styled.div``;

function Structs() {
  const [structs, setStructs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newStruct, setNewStruct] = useState({ label: "", value: "" });
  const [edit, setEdit] = useState(false);
  const [editingId, setEditingId] = useState("");

  useEffect(() => {
    firebase
      .database()
      .ref("structs")
      .on("value", function (snap) {
        setStructs(snap.val() || {});
      });
  }, []);

  function newRegister() {
    setEdit(false);
    setNewStruct({ label: "", value: "" });
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  function saveStruct() {
    if (!edit) {
      let path = firebase.database().ref("structs");

      path.push(newStruct).then((res) => {
        setNewStruct({ label: "", value: "" });
        closeModal();
      });
    } else {
      let path = firebase.database().ref(`structs/${editingId}`);
      path.set(newStruct).then(() => {
        setNewStruct({ label: "", value: "" });
        closeModal();
      });
    }
  }

  function handleChange(e) {
    setNewStruct({ ...newStruct, [e.target.name]: e.target.value });
  }

  function handleRemove(id) {
    const path = firebase.database().ref(`structs/${id}`);

    const ok = window.confirm("Tem certeza que deseja excluir ?");

    if (ok) path.remove();
    setEdit(false);
    setNewStruct({ label: "", value: "" });
    closeModal();
  }

  function handleEdit(id) {
    firebase
      .database()
      .ref(`structs/${id}`)
      .on("value", function (snap) {
        setNewStruct(snap.val());
        setEditingId(id);
        setEdit(true);
        setShowModal(true);
      });
  }

  return (
    <InversorWrapper>
      <Title>Estruturas</Title>

      <ListItems onEdit={handleEdit} onRemove={handleRemove} items={structs} />

      <EmptyMessage onClick={newRegister}>
        Cadastrar Estrutura
        <i className="fas fa-plus" />
      </EmptyMessage>

      <Modal
        edit={edit}
        close={closeModal}
        visible={showModal}
        title="estruturas"
        clickBackdrop={() => setShowModal(false)}
      >
        <InputLabel>
          <Label>Nome do produto</Label>
          <Input value={newStruct.label} name="label" onChange={handleChange} />
        </InputLabel>

        <InputLabel>
          <Label>Valor (R$)</Label>
          <Input value={newStruct.value} name="value" onChange={handleChange} />
        </InputLabel>

        <SaveButton onClick={saveStruct}>
          {edit ? "Editar" : "Salvar"}
        </SaveButton>

        {edit && (
          <DeleteButton onClick={() => handleRemove(editingId)}>
            Excluir
          </DeleteButton>
        )}
      </Modal>
    </InversorWrapper>
  );
}

export default Structs;
