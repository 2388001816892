import { useEffect, useState } from "react";
import styled from "styled-components";
import { Title } from "../../components/shared";
import OrcamentosList from "../../components/orcamentosList";
import firebase from "../../firebase";

export const InversorWrapper = styled.div``;

function Orcamentos({searchText}) {
  const [orcamentos, setOrcamentos] = useState({});

  useEffect(() => {
    firebase
      .database()
      .ref("orcamentos")
      .on("value", function (snap) {
        setOrcamentos(snap.val() || {});
      });
  }, []);


  function handleRemove(id) {
    const path = firebase.database().ref(`orcamento/${id}`);

    const ok = window.confirm("Tem certeza que deseja excluir ?");

    if (ok) path.remove();
  }

  return (
    <InversorWrapper>
      <Title>Orçamentos</Title>

      <OrcamentosList onRemove={handleRemove} searchText={searchText} items={orcamentos} />
    </InversorWrapper>
  );
}

export default Orcamentos;
