import styled from "styled-components";

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 400px;
  height: 90vh;
`;

export const Input = styled.input`
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid ${(props) => (props.error ? "red" : "#ccc")};
  outline: none;
`;

export const Image = styled.img`
  object-fit: contain;
  height: 150px;
  margin-bottom: 10px;
`;

export const LoginButton = styled.button`
  color: #fff;
  background: #1e439c;
  padding: 15px;
  border-radius: 5px;
  border: none;
`;

export const Error = styled.div`
  padding-bottom: 15px;
  color: red;
`;
