import { useState, useEffect } from "react";
import styled from "styled-components";
import ListItems from "../../components/listItem";
import {
  Title,
  EmptyMessage,
  Input,
  InputLabel,
  Label,
  SaveButton,
  DeleteButton,
} from "../../components/shared";
import firebase from "../../firebase";
import Modal from "../../components/modal";

export const InversorWrapper = styled.div``;

function Modules() {
  const [modules, setModules] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editingId, setEditingId] = useState("");
  const [newModule, setNewModule] = useState({
    label: "",
    value: "",
    potencia: "",
    area: "",
    active: false,
  });

  useEffect(() => {
    firebase
      .database()
      .ref("modules")
      .on("value", function (snap) {
        setModules(snap.val() || {});
      });
  }, []);

  function newRegister() {
    setShowModal(true);
    setEdit(false);
    setNewModule({ label: "", value: "", area: "", active: false });
  }

  function closeModal() {
    setShowModal(false);
  }

  function handleRemove(id) {
    const path = firebase.database().ref(`modules/${id}`);

    const ok = window.confirm("Tem certeza que deseja excluir ?");

    if (ok) path.remove();
    setEdit(false);
    setNewModule({ label: "", value: "", area: "", active: false });
    closeModal();

  }

  function saveStruct() {
    if (!edit) {
      let path = firebase.database().ref("modules");

      path.push(newModule).then((res) => {
        setNewModule({ label: "", value: "", area: "", active: false });
        closeModal();
      });
    } else {
      let path = firebase.database().ref(`modules/${editingId}`);
      path.set(newModule).then(() => {
        setNewModule({ label: "", value: "", potencia: "", active: false });
        closeModal();
      });
    }
  }

  function setActive() {
    Object.keys(modules).map((index) => {
      firebase.database().ref(`modules/${index}/active`).set(false);
    });

    firebase.database().ref(`modules/${editingId}/active`).set(true);
  }

  function handleChange(e) {
    setNewModule({ ...newModule, [e.target.name]: e.target.value });
  }

  function handleEdit(id) {
    firebase
      .database()
      .ref(`modules/${id}`)
      .on("value", function (snap) {
        setNewModule(snap.val());
        setEditingId(id);
        setEdit(true);
        setShowModal(true);
      });
  }

  return (
    <InversorWrapper>
      <Title>Modulos</Title>

      <ListItems
        potencia
        area
        onEdit={handleEdit}
        onRemove={handleRemove}
        items={modules}
      />

      <EmptyMessage onClick={newRegister}>
        Cadastrar Módulos
        <i className="fas fa-plus" />
      </EmptyMessage>

      <Modal
        clickBackdrop={closeModal}
        edit={edit}
        close={closeModal}
        visible={showModal}
        title="modulo"
      >
        <InputLabel>
          <Label>Nome do produto</Label>
          <Input value={newModule.label} name="label" onChange={handleChange} />
        </InputLabel>

        <InputLabel>
          <Label>Potência (KW)</Label>
          <Input
            value={newModule.potencia}
            name="potencia"
            onChange={handleChange}
          />
        </InputLabel>

        <InputLabel>
          <Label>Área da placa (m²)</Label>
          <Input value={newModule.area} name="area" onChange={handleChange} />
        </InputLabel>

        <InputLabel>
          <Label>Valor (R$)</Label>
          <Input value={newModule.value} name="value" onChange={handleChange} />
        </InputLabel>

        <SaveButton onClick={saveStruct}>
          {edit ? "Editar" : "Salvar"}
        </SaveButton>

        {edit && (
          <>
          <SaveButton
            disabled={newModule.active}
            edit={edit}
            onClick={setActive}
          >
            {newModule.active && <i className="fas fa-check"></i>}
            {newModule.active ? " Módulo ativo" : "Ativar módulo"}
          </SaveButton>
          <DeleteButton onClick={() => handleRemove(editingId)}>
            Excluir
          </DeleteButton>
          </>
        )}
      </Modal>
    </InversorWrapper>
  );
}

export default Modules;
