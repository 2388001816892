import React, { useEffect, useState } from "react";
import { Title, Input } from "../../components/shared";
import firebase from "../../firebase";

function Config() {
  const [config, setConfig] = useState({});

  useEffect(() => {
    firebase
      .database()
      .ref("config")
      .on("value", (res) => {
        setConfig(res.val() || {});
      });
  }, []);

  function saveConfig(e) {
    firebase
      .database()
      .ref("config/" + e.target.name)
      .set(e.target.value);
  }

  return (
    <div>
      <Title>Configurações</Title>

      <div>
        <strong>Mão de obra até 600kw: </strong>
        <Input
          onChange={saveConfig}
          name="lessThan600"
          placeholder="R$"
          value={config.lessThan600}
        />

        <strong>Mão de obra acima de 600kw: </strong>
        <Input
          onChange={saveConfig}
          name="moreThan600"
          placeholder="R$"
          value={config.moreThan600}
        />

        <strong>Mão de obra acima de 2800kw: </strong>
        <Input
          onChange={saveConfig}
          name="moreThan3000"
          placeholder="R$"
          value={config.moreThan3000}
        />

        <strong>KWh / KWp </strong>
        <Input
          onChange={saveConfig}
          name="sunIrradiation"
          value={config.sunIrradiation}
        />

        <strong>Valor máximo de sobrecarga </strong>
        <Input
          onChange={saveConfig}
          name="minPercentual"
          value={config.minPercentual}
        />
      </div>
    </div>
  );
}

export default Config;
