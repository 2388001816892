import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { formatMoney, formatPhoneNumber, formataCPF } from "../utils";
import moment from "moment";
import firebase from "../firebase";

export const ItemsWrapper = styled.div`
  width: 100%;
  font-size: 14px;
  max-height: 600px;
  overflow: auto;
  position: relative;
`;

export const TableWrapper = styled.div``;

export const Item = styled.a`
  display: flex;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  color: #222;

  :hover {
    background-color: #eee;
  }

  div {
    width: 25%;
    :first-child {
      font-weight: bold;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  div {
    width: 25%;
    font-weight: bold;
  }
`;

export const HiddenItem = styled.div`
  transition: height 1s linear;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #ccc;
    border-radius: 8px;
  
  @media (max-width: 760px) {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 760px) {
    flex-direction: column;
  }
`;

export const Label = styled.div`
`;

export const HiddenMobile = styled.div`

  @media (max-width: 760px) {
    display: none;
  }
`;

export const HiddenPhone = styled.div`

  @media (max-width: 540px) {
    display: none;
  }
`;

export const Value = styled.div`
  font-weight: bold;
`;

export const DeleteButton = styled.button`
  background: red;
  margin-top: 20px;
  color: #fff;
  border: none;
  padding: 10px;
`;

function OrcamentosList(props) {
  const { items, searchText } = props;
  const [orcamentos, setOrcamentos] = useState(items);

  useEffect(() => {
    Object.keys(items).map((index) => {
      items[index].opened = false;
    });

    setOrcamentos(items);
  }, [items]);

  useEffect(() => {
    handleSearch(searchText);
  }, [searchText])

  function tooglleItem(index) {
    let item = orcamentos[index];
    item.opened = !item.opened;

    setOrcamentos({
      ...orcamentos,
      [index]: item,
    });
  }

  function deleteOrcamento(index) {
    if (window.confirm("Tem certeza que deseja apagar esse orçamento ?")) {
      firebase
        .database()
        .ref("/orcamentos/" + index)
        .remove();
    }
  }


  function handleSearch(text) {
    const filter = {};

    Object.keys(orcamentos).map((index) => {
      const changedProposta = `00${orcamentos[index].proposta}${moment(
        orcamentos[index].date
      ).year()}`;

      if (
        orcamentos[index].clientName.toLowerCase().indexOf(text.toLowerCase()) >
        -1 ||
        changedProposta.indexOf(text) > -1
      ) {
        filter[index] = orcamentos[index];
      }
    });

    setOrcamentos(filter);

    if (!text || text.length < 1) {
      setOrcamentos(items);
    }
  }

  return (
    <TableWrapper>
      <Header>
        <div style={{ width: 110 }}>Proposta</div>
        <div>Cliente</div>
        <HiddenPhone>Telefone</HiddenPhone>
        <HiddenMobile>Vendedor</HiddenMobile>
        <div>Valor</div>
        <i className=""></i>
      </Header>
      <ItemsWrapper>
        {Object.keys(orcamentos)
          .reverse()
          .map((index) => (
            <div
              key={index}
              style={{
                marginBottom: orcamentos[index].opened ? 20 : 0,
                marginTop: orcamentos[index].opened ? 20 : 0,
                boxShadow: orcamentos[index].opened
                  ? "1px 1px  13px rgba(0,0,0,0.3)"
                  : "none",
              }}
            >
              <Item onClick={() => tooglleItem(index)}>
                <div
                  style={{
                    width: 100,
                    flexDirection: "column",
                    wordWrap: "normal",
                  }}
                >
                  <div>
                    #00{orcamentos[index].proposta}
                    {moment(orcamentos[index].date).year()}
                  </div>
                </div>
                <div>{orcamentos[index].clientName}</div>
                <HiddenPhone>{formatPhoneNumber(orcamentos[index].phone)}</HiddenPhone>
                <HiddenMobile>{orcamentos[index].user?.name}</HiddenMobile>
                <div>R$ {formatMoney(orcamentos[index].total)}</div>
                <i
                  className={`fas fa-chevron-${orcamentos[index].opened ? "up" : "down"
                    }`}
                ></i>
              </Item>
              {orcamentos[index].opened && (
                <HiddenItem>
                  <Row
                    style={{ marginBottom: 20, justifyContent: "flex-start" }}
                  >
                    <Label style={{ marginRight: 30, fontSize: 20 }}>
                      Nº da proposta:
                    </Label>
                    <Value style={{ fontSize: 20 }}>
                      #00{orcamentos[index].proposta}
                      {moment(orcamentos[index].date).year()}
                    </Value>
                  </Row>
                  <Row>
                    <Label>Cliente: </Label>
                    <Value>{orcamentos[index].clientName}</Value>
                  </Row>
                  <Row>
                    <Label>CPF: </Label>
                    <Value>{formataCPF(orcamentos[index].cpf)}</Value>
                  </Row>
                  <Row>
                    <Label>Fone para contato: </Label>
                    <Value>{formatPhoneNumber(orcamentos[index].phone)}</Value>
                  </Row>
                  <Row>
                    <Label>Data da proposta: </Label>
                    <Value>
                      {orcamentos[index].date
                        ? moment(orcamentos[index].date).format("DD/MM/YYYY")
                        : "-"}
                    </Value>
                  </Row>
                  <Row>
                    <Label>Média de produção KWh/M: </Label>
                    <Value>{orcamentos[index].kw}</Value>
                  </Row>
                  <Row>
                    <Label>Quantidade de placas: </Label>
                    <Value>{orcamentos[index].quantPlates}</Value>
                  </Row>

                  <Row>
                    <Label>Inversor: </Label>
                    <Value>{orcamentos[index].inversor.label}</Value>
                  </Row>

                  <Row>
                    <Label>Tipo de estrutura: </Label>
                    <Value>{orcamentos[index].structType.label}</Value>
                  </Row>

                  <Row>
                    <Label>Placa: </Label>
                    <Value>{orcamentos[index].module.label}</Value>
                  </Row>

                  <Row>
                    <Label>Área necessária: </Label>
                    <Value>{orcamentos[index].area} m²</Value>
                  </Row>
                  <Row>
                    <Label>Tempo de retorno: </Label>
                    <Value>{orcamentos[index].returnTime} meses</Value>
                  </Row>
                  <Row>
                    <Label>Valor da proposta: </Label>
                    <Value>R$ {formatMoney(orcamentos[index].total)}</Value>
                  </Row>
                  <Row>-----------------------------</Row>
                  <Row>
                    <Label>Vendedor: </Label>
                    <Value>{orcamentos[index].user?.name}</Value>
                  </Row>
                  <Row>
                    <Label>Fone: </Label>
                    <Value>
                      {formatPhoneNumber(orcamentos[index].user?.phone)}
                    </Value>
                  </Row>
                  <Row>
                    <Label>CPF: </Label>
                    <Value>{formataCPF(orcamentos[index].user?.cpf)}</Value>
                  </Row>

                  <Row>
                    <DeleteButton onClick={() => deleteOrcamento(index)}>
                      Excluir orçamento
                    </DeleteButton>
                  </Row>
                </HiddenItem>
              )}
            </div>
          ))}
      </ItemsWrapper>
    </TableWrapper>
  );
}

export default OrcamentosList;
