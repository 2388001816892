import { useEffect, useState } from "react";
import styled from "styled-components";
import ListItems from "../../components/itemList";
import {
  Title,
  EmptyMessage,
  Input,
  InputLabel,
  Label,
  SaveButton,
  SaveUser,
  Text,
  DeleteButton,
} from "../../components/shared";
import firebase from "../../firebase";
import Modal from "../../components/modal";

export const InversorWrapper = styled.div``;

const BLANK_USER = {
  name: "",
  email: "",
  cpf: "",
};

function Users({searchText}) {
  const [users, setUsers] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newUser, setNewUser] = useState(BLANK_USER);
  const [editingId, setEditingId] = useState("");
  const [edit, setEdit] = useState(false);
  const [actives, setActives] = useState(0);
  const [inactives, setInactives] = useState(0);

  useEffect(() => {
    firebase
      .database()
      .ref("users")
      .on("value", function (snap) {
        const users = snap.val();
        setUsers(users || {});
        setFiltered(users || {});
      });
  }, []);

  useEffect(() => {
    handleSearch(searchText);
  },[searchText])

  useEffect(() => {
    let actives = 0;
    let inactives = 0;

    Object.keys(users).map((index) => {
      if (users[index].active) {
        return actives++;
      }

      inactives++;
    });

    setActives(actives);
    setInactives(inactives);
  }, [users]);

  function newRegister() {
    setEdit(false);
    setNewUser(BLANK_USER);
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  function handleSearch(text) {
    const filter = {};

    Object.keys(users).map((index) => {

      if (users[index].name.toLowerCase().indexOf(text.toLowerCase()) > -1) {
        filter[index] = users[index];
      }

      if (users[index].cpf.toLowerCase().indexOf(text.toLowerCase()) > -1) {
        filter[index] = users[index];
      }
    });

    setFiltered(filter);

    if (!text || text.length < 1) {
      setFiltered(users);
    }
  }

  function saveStruct() {
    if (!edit) {
      let path = firebase.database().ref("users");

      path.push(newUser).then((res) => {
        setNewUser(BLANK_USER);
        closeModal();
      });
    } else {
      let path = firebase.database().ref(`users/${editingId}`);
      path.set(newUser).then(() => {
        setNewUser(BLANK_USER);
        closeModal();
      });
    }
  }

  function setActive() {
    firebase.database().ref(`users/${editingId}/active`).set(true);
  }

  function setInactive() {
    firebase.database().ref(`users/${editingId}/active`).set(false);
  }

  function handleChange(e) {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  }

  function handleRemove(id) {
    const path = firebase.database().ref(`users/${id}`);

    const ok = window.confirm("Tem certeza que deseja excluir ?");

    if (ok) path.remove();
    setEdit(false);
    setNewUser(BLANK_USER);
    closeModal();
    
  }

  function handleEdit(id) {
    firebase
      .database()
      .ref(`users/${id}`)
      .on("value", function (snap) {
        setNewUser(snap.val());
        setEditingId(id);
        setEdit(true);
        setShowModal(true);
      });
  }

  return (
    <InversorWrapper>
      <Title>Usuários</Title>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Text style={{ marginRight: 20, color: "rgb(46, 125, 50)" }}>
          Ativos:{" "}
          <strong style={{ color: "rgb(46, 125, 50)" }}>{actives}</strong>
        </Text>
        <Text style={{ color: "red" }}>
          Inativos: <strong style={{ color: "red" }}>{inactives}</strong>
        </Text>
      </div>
      <ListItems items={filtered} onEdit={handleEdit} onRemove={handleRemove} />
      <EmptyMessage onClick={newRegister}>
        Cadastrar usuário
        <i className="fas fa-plus" />
      </EmptyMessage>
      <Modal clickBackdrop={closeModal} close={closeModal} visible={showModal} title="usuário" edit={edit}>
        <InputLabel>
          <Label>Nome do usuário</Label>
          <Input value={newUser.name} name="name" onChange={handleChange} />
        </InputLabel>

        <InputLabel>
          <Label>Cpf</Label>
          <Input value={newUser.cpf} name="cpf" onChange={handleChange} />
        </InputLabel>

        <InputLabel>
          <Label>Telefone para contato</Label>
          <Input value={newUser.phone} name="phone" onChange={handleChange} />
        </InputLabel>

        <SaveButton onClick={saveStruct}>
          {edit ? "Editar" : "Salvar"}
        </SaveButton>

        {edit && (
          <>
          <SaveUser
            edit={edit}
            onClick={() => (newUser.active ? setInactive() : setActive())}
            active={newUser.active}
          >
            {newUser.active ? "Inativar usuário" : "Ativar usuário"}
          </SaveUser>
          <DeleteButton onClick={() => handleRemove(editingId)}>
            Excluir
          </DeleteButton>
          </>
        )}
      </Modal>
    </InversorWrapper>
  );
}

export default Users;
