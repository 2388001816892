import styled, { css } from "styled-components";

export const ListItems = styled.div`
  margin-bottom: 20px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  cursor: pointer;

  :hover {
    background: ${({ active }) => (active ? "" : "#eee")};
  }

  @media (max-width: 760px) {
    flex-wrap: wrap;
  }
`;

export const Label = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

export const Dot = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 15px;
  border: 1px solid #888;
  margin-right: 5px;
  background: ${({ active }) => (active ? "green" : "red")};
`;

export const Value = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 760px) {
    font-size: 14px;
  }
`;

export const Column = styled.div``;

export const Description = styled.div`
  width: 50%;

  @media (max-width: 760px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const EditButtons = styled.div`
  display: flex;
  align-items: center;

  div {
    font-weight: bold;
  }
`;

const activeStyle = css`
  background: #11998e;
  border-radius: 5px;
  color: #fff;

  i {
    color: #fff;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;

  ${(props) => props.active && activeStyle}

  @media (max-width: 760px) {
    font-size: 12px;
  }

  span {
    @media (max-width: 760px) {
      font-size: 13px;
    }
  }
`;

export const Values = styled.div`
  span {
    margin-left: 30px;
    white-space: nowrap;

    @media (max-width: 760px) {
      margin-left: 10px;
    }
  }
`;

function ListItem(props) {
  const { items, onEdit, onRemove, potencia, area } = props;

  return (
    <ListItems className="table-container">
      {Object.keys(items).map((index) => (
        <ItemWrapper key={`${items[index].label + index}`} onClick={() => onEdit(index)} active={items[index].active}>

          <Description>
            <Label>Nome</Label>
            <Value> <Dot active={items[index].active}/>{items[index].name}</Value>
          </Description>

          {items[index].cpf && (
            <Column>
              <Label>CPF</Label>
              <Value>{items[index].cpf}</Value>
            </Column>
          )}

          {items[index].email && (
            <Column>
              <Label>Email</Label>
              <Value>{items[index].email}</Value>
            </Column>
          )}

          <Column>
            <Label>Telefone</Label>
            <Value>{items[index].phone}</Value>
          </Column>
        </ItemWrapper>
      ))}
      {/* <table
        style={{ background: "transparent" }}
        className="table is-fullwidth"
      >
        <thead>
          <tr>
            <th>Opções</th>
            <th>Descrição</th>
            {area && <th>Tamanho</th>}
            {potencia && <th>Potência</th>}
            <th>Valor</th>
          </tr>
        </thead>

        <tbody>
          {Object.keys(items).map((index) => (
            <tr
              key={index}
              className={items[index].active ? "is-selected" : ""}
            >
              <td>
                <EditButtons>
                  {onRemove && (
                    <IconButton negative onClick={() => onRemove(index)}>
                      <i className="fas fa-times" />
                    </IconButton>
                  )}

                  {onEdit && (
                    <IconButton>
                      <i
                        className="fas fa-edit"
                        onClick={() => onEdit(index)}
                      />
                    </IconButton>
                  )}
                </EditButtons>
              </td>

              <td>{items[index].label}</td>

              {items[index].area && <td>{items[index].area} m²</td>}

              {items[index].potencia && (
                <td>
                  {items[index].potencia && (
                    <span>{items[index].potencia} KWp</span>
                  )}
                </td>
              )}

              <td>
                {items[index].value && (
                  <span>R$ {formatMoney(items[index].value, 2)}</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table> */}
    </ListItems>
  );
}

export default ListItem;
