import styled, { css } from "styled-components";

export const DashboardWrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 40px;

  @media (max-width: 760px) {
    padding: 0 10px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;

  @media (max-width: 760px) {
    position: fixed;
    background: #dee1ee;
    width: 100%;
    padding: 0 20px;
  }
`;

export const Image = styled.img`
  display: none;

  @media (max-width: 760px) {
    object-fit: cover;
    margin-bottom: 20px;
    padding: 20px;
  }
`;

export const Username = styled.div`
  font-size: 20px;
  font-weight: 500;
  @media (max-width: 760px) {
    display: none;
  }
`;

export const Email = styled.div`
  font-size: 12px;
  @media (max-width: 760px) {
    display: none;
  }
`;

export const UserOptions = styled.div`
  display: flex;
`;

export const ButtonImage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
`;

export const Exit = styled.button`
  display: flex;
  cursor: pointer;
  border: none;
  margin-left: 10px;
  border-radius: 50px;
  padding: 10px;
  color: #333;
  background: #fff;
  font-size: 18px;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;

  :hover {
    background: #eee;
  }
`;

const openedStyles = css`
  position: absolute;
  z-index: 5;
  background: #fff;
  height: 100vh;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;

  @media (max-width: 760px) {
    display: ${(props) => (props.opened ? "flex" : "none")};
    ${(props) => props.opened && openedStyles}
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BackgroundMenu = styled.div`
  display: ${(props) => (props.opened ? "flex" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  min-height: 100vh;
  width: calc(100% - 250px);

  @media (max-width: 760px) {
    display: ${(props) => (props.opened ? "flex" : "none")};
  }
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  height: 50px;
  cursor: pointer;

  :before {
    content: "";
    height: 20px;
    width: ${({ active }) => (active ? 2 : 0)}px;
    background: #111;
  }

  i {
    margin-right: 10px;
    width: 20px;
  }
`;

export const PageWrapper = styled.div`
  display: flex;

  @media (max-width: 760px) {
    padding-top: 100px;
  }
`;

export const SearchBarWrapper = styled.div`
  flex: 1;
`;

export const Input = styled.input`
  flex: 1;
  margin-left: 70px;
  padding-left: 20px;
  background: #f4f4fb;
  height: 40px;
  border-radius: 20px;
  outline: none;
  font-size: 16;
  border: none;
  width: 90%;
  color: #333;

  @media (max-width: 760px) {
    margin-left: 10px;
  }
`;

export const Title = styled.h1`
  @media (max-width: 760px) {
    display: none;
  }
`;

export const Main = styled.div`
  width: 100%;
  background: #f4f4fb;
  padding: 30px 40px;
  border-radius: 40px;
  margin-bottom: 40px;

  @media (max-width: 760px) {
    padding: 20px;
    padding-top: 40px;
    margin-left: 0;
    min-height: 100vh;
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;
