import React from "react";
import styled from "styled-components";
import { Title, Row } from "./shared";
import { motion } from "framer-motion";

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Backdrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ModalContent = styled(motion.div)`
  background: #fff;
  max-width: 90%;
  min-height: 90vh;
  width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 40px;
  position: absolute;
  bottom: 0;

  @media (max-width: 760px) {
    padding: 20px;
    max-width: 99%;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-top-right-radius: 25px;
`;

function Modal(props) {
  const { title, children, clickBackdrop, visible, close, edit } = props;

  if (!visible) return null;

  return (
    <ModalWrapper visible>
      <Backdrop onClick={clickBackdrop} />
      <ModalContent
        initial={{ y: 500 }}
        animate={{ y: 100 }}
        transition={{ duration: 0.3 }}
      >
        <Row>
          <CloseButton onClick={close}>
            <i className="fas fa-times"></i>
          </CloseButton>

          <Title>
            {!edit ? "Cadastrar" : "Editar"} {title}
          </Title>
          {children}
        </Row>
      </ModalContent>
    </ModalWrapper>
  );
}

export default Modal;
