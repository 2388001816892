import React, { useEffect, useState } from "react";
import firebase from "../../firebase";
import {
  DashboardWrapper,
  Header,
  Image,
  Username,
  UserOptions,
  Exit,
  Buttons,
  Button,
  ButtonImage,
  PageWrapper,
  Main,
  Email,
  BackgroundMenu,
  UserInfo,
  SearchBarWrapper,
  Title,
  Input,
} from "./dashboard.styles";
import Logo from "../../assets/logo.jpeg";
import inversors from "../../assets/inversor.png";
import modules from "../../assets/modulos.png";
import structs from "../../assets/estrutura.png";
import sheets from "../../assets/google-sheets.png";
import Inversor from "./inversor";
import Modules from "./modules";
import Structs from "./structs";
import Users from "./users";
import Config from "./config";
import Orcamentos from "./orcamentos";
import { IconButton } from "../../components/shared";

function Dashboard(props) {
  const [currentUser, setCurrentUser] = useState(null);
  const [page, setPage] = useState("inversors");
  const [opened, setOpened] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchPlaceholder, setSearchPlaceholder] = useState('');

  useEffect(() => {
    firebase.auth().onAuthStateChanged((observable) => {
      if (!observable) {
        return window.location.replace("/login");
      }

      setCurrentUser(observable);
    });
  }, []);

  useEffect(() => {
    if(page === 'users' || page === 'orcamentos') {
      if(page === 'users') {
        setSearchPlaceholder('usuários');
      }

      if(page === 'orcamentos') {
        setSearchPlaceholder('orçamentos');
      }
      setShowSearchBar(true);
    } else {
      setShowSearchBar(false);
    }
  },[page])

  if (!currentUser) return null;

  function logout() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        return window.location.replace("/login");
      });
  }

  

  function getPageContent() {
    switch (page) {
      case "inversors":
        return <Inversor />;
      case "modules":
        return <Modules />;
      case "structs":
        return <Structs />;
      case "users":
        return <Users searchText={searchText}/>;
      case "config":
          return <Config />;
      case "orcamentos":
        return <Orcamentos searchText={searchText} />;
      default:
        return null;
    }
  }

  function openMenu() {
    setOpened(!opened);
  }

  function setActivePage(page) {
    setOpened(false);
    setPage(page);
  }

  function handleSearch(e){
    setSearchText(e.target.value);
  }


  return (
    <DashboardWrapper>
      <Header>
        <IconButton hideMobile onClick={openMenu}>
          <i className="fas fa-bars"></i>
        </IconButton>
        <Title style={{ fontWeight: 500, fontSize: 25 }}>Mads Admin</Title>

        {showSearchBar && (
          <SearchBarWrapper>
            <Input value={searchText} onChange={handleSearch} placeholder={`Buscar ${searchPlaceholder}`} />
          </SearchBarWrapper>
        )} 

        <UserOptions>
          <Exit>
            <i className="fas fa-cog" />
          </Exit>
          <Exit onClick={logout}>
            <i className="fas fa-sign-out-alt" />
          </Exit>
        </UserOptions>
      </Header>

      <PageWrapper>
        <Buttons opened={opened}>
          <UserInfo>
            <Image src={Logo} />
            <Username>{currentUser.email.split('@')[0]}</Username>
            <Email>{currentUser.email}</Email>
          </UserInfo>

          <Button
            onClick={() => setActivePage("inversors")}
            active={page === "inversors"}
          >
            <ButtonImage src={inversors} />
            Inversores
          </Button>
          <Button
            onClick={() => setActivePage("modules")}
            active={page === "modules"}
          >
            <ButtonImage src={modules} />
            Modulos
          </Button>
          <Button
            onClick={() => setActivePage("structs")}
            active={page === "structs"}
          >
            <ButtonImage src={structs} />
            Estruturas
          </Button>
          <Button
            onClick={() => setActivePage("users")}
            active={page === "users"}
          >
            <i style={{ marginLeft: 10 }} className="fas fa-user" />
            Usuários
          </Button>
          <Button
            onClick={() => setActivePage("config")}
            active={page === "config"}
          >
            <i style={{ marginLeft: 10 }} className="fas fa-cogs" />
            Configurações
          </Button>
          <Button
            onClick={() => setActivePage("orcamentos")}
            active={page === "orcamentos"}
          >
            <ButtonImage src={sheets} />
            Orçamentos
          </Button>
        </Buttons>
        <BackgroundMenu onClick={() => setOpened(false)} opened={opened} />

        <Main>{getPageContent()}</Main>
      </PageWrapper>
    </DashboardWrapper>
  );
}

export default Dashboard;
