import styled from "styled-components";

export const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 15px;
`;

export const InputLabel = styled.div``;

export const Label = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const EmptyMessage = styled.button`
  border: 1px solid #ccc;
  background-color: transparent;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;

  i {
    margin-left: 10px;
  }
`;

export const Input = styled.input`
  padding-left: 10px;
  font-size: 14px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #aaa;
  width: 98%;
  height: 50px;
  margin-bottom: 10px;
`;

export const SaveButton = styled.button`
  color: #fff;
  padding: 10px 25px;
  border: none;
  border-radius: 5px;
  background: ${(props) => (props.edit ? "#11998e" : "#1e439c")};
  margin-left: ${(props) => (props.edit ? "10px" : 0)};
  cursor: pointer;

  :disabled {
    background: transparent;
    color: #11998e;
  }
`;

export const DeleteButton = styled.button`
  color: #fff;
  padding: 10px 25px;
  border: none;
  border-radius: 5px;
  background: red;
  margin-left: 10px;
  cursor: pointer;

  :disabled {
    background: transparent;
    color: #11998e;
  }
`;

export const SaveUser = styled.button`
  color: #fff;
  padding: 10px 25px;
  border: none;
  border-radius: 5px;
  margin-left: ${(props) => (props.edit ? "10px" : 0)};
  background: ${(props) => (!props.active ? "#11998e" : "#dd2c00")};
  cursor: pointer;
`;

export const IconButton = styled.button`
  border: none;
  background: transparent;
  outline: none;
  padding: 3px 5px;
  color: ${(props) => (props.negative ? "red" : "#333")};
  margin-right: 5px;

  @media (min-width: 768px) {
    display: ${(props) => (props.hideMobile ? "none" : "block")};
  }

  :hover {
    cursor: pointer;
  }
`;

export const Text = styled.div``;

export const Row = styled.div`
  max-width: 700px;
  margin: 0 auto;
`;
