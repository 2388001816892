export const formatMoney = (number, fixed = 2) => {
  if (!number) return 0;

  const parsedNumber = parseFloat(number).toFixed(fixed);

  let response = String(parsedNumber);
  if (response.indexOf(".") !== -1) {
    response = response.replace(".", ",");
  }

  return String(response).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
};

export function formatPhoneNumber(v = "") {
  var r = v.replace(/\D/g, "");
  r = r.replace(/^0/, "");
  if (r.length > 10) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (r.length > 5) {
    r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else {
    r = r.replace(/^(\d*)/, "($1");
  }
  return r;
}

export function formataCPF(cpf = "") {
  //retira os caracteres indesejados...
  cpf = cpf?.replace(/[^\d]/g, "");

  //realizar a formatação...
  return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}
