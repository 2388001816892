import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

// FOR TEST
// const firebaseConfig = {
//   apiKey: "AIzaSyBqAqbplMkBNHxi0HRot3XoOsAdvU0CaXQ",
//   authDomain: "todo-list-1286.firebaseapp.com",
//   databaseURL: "https://todo-list-1286.firebaseio.com",
//   projectId: "todo-list-1286",
//   storageBucket: "todo-list-1286.appspot.com",
//   messagingSenderId: "550910938923",
//   appId: "1:550910938923:web:b7f6b123c2149a89b53f6e",
// };

var firebaseConfig = {
  apiKey: "AIzaSyBxoRC_Q0-hLIo_seYpo7AVnBhN2PUl-Wo",
  authDomain: "mads-solar.firebaseapp.com",
  projectId: "mads-solar",
  storageBucket: "mads-solar.appspot.com",
  messagingSenderId: "300958438232",
  appId: "1:300958438232:web:dd3bfe221c0cd586318b2d",
  measurementId: "G-5KGWFHPP6B",
};

export default firebase.initializeApp(firebaseConfig);
